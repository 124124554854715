import { Component, Vue, Watch } from 'vue-property-decorator';
import { SidemenuItem } from '@/components/sidemenu/Sidemenu';
import { getStatusLabel } from '@/support/ReportStatus';

@Component<JuridischeZaken>({})
export default class JuridischeZaken extends Vue {
  public $pageTitle = 'Juridische Zaken';

  protected isLoading = true;

  protected items: SidemenuItem[] = [
    {
      title: 'Dashboard',
      link: '/juridische-zaken',
      icon: {
        key: 'dashboard',
      },
    },
    {
      title: getStatusLabel('specials_in_review'),
      link: '/juridische-zaken/reports?status=specials_in_review',
      icon: {
        key: 'done_all',
      },
    },
  ];

  public mounted() {
    this.initialize();
  }

  protected initialize() {
    this.emitBreadcrumb();
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Juridische Zaken' },
        ],
      });
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}
